import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import ButtonArrow from '../components/ButtonArrow'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'

import divider from '../images/divider.svg'

const HeaderContainer = styled.div`
  width: 100%;
  position: relative;
`

const HeaderImage = styled(GatsbyImage)`
  height: 100vh;
  min-height: 500px;
  width: 100%;
  object-fit: cover;
  filter: brightness(110%);
`

const HeaderText = styled.h1`
  position: absolute;
  bottom: 200px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-family: Montserrat, sans-serif;
  font-size: 90px;
  font-weight: 400;
  letter-spacing: 16px;
  @media (max-width: 1200px) {
    font-size: 76px;
  }
  @media (max-width: 1050px) {
    font-size: 66px;
  }
  @media (max-width: 930px) {
    font-size: 56px;
  }
  @media (max-width: 470px) {
    font-size: 48px;
  }
  @media (max-width: 420px) {
    font-size: 42px;
  }
  @media (max-width: 370px) {
    font-size: 36px;
  }
`

const TextContainer = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  margin-top: 100px;
  margin-bottom: 140px;
  @media (max-width: 820px) {
    flex-direction: column;
    margin-bottom: 100px;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-left: 80px;
  padding-right: 90px;
  @media (max-width: 1080px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 980px) {
    padding-left: ${p => p.theme.paddingOnSides};
    padding-right: 50px;
  }
  @media (max-width: 820px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 80px;
  }
`

const Right = styled.div`
  position: relative;
  width: 50%;
  padding-left: 90px;
  padding-right: 80px;
  @media (max-width: 1080px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 980px) {
    padding-left: 50px;
    padding-right: ${p => p.theme.paddingOnSides};
  }
  @media (max-width: 820px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const Paragraph = styled.p`
  margin-bottom: 26px;
`

const Divider = styled.img`
  margin-top: 10px;
  margin-bottom: 30px;
`

const Heading = styled.h1`
  font-family: Montserrat, 'sans-serif';
  font-size: 44px;
  font-weight: 600;
  letter-spacing: 2.6px;
  color: ${p => p.theme.textColorDark};
  line-height: 1.24;
  margin-left: -3px;
  @media (max-width: 1110px) {
    font-size: 40px;
  }
  @media (max-width: 890px) {
    font-size: 36px;
  }
  @media (max-width: 400px) {
    font-size: 34px;
  }
`

// const TopHeading = styled.h2`
//   color: ${p => p.theme.midDarkGray};
//   font-size: 12px;
//   letter-spacing: 3.6px;
// `

const ImageContainer = styled.div`
  display: flex;
  margin-bottom: 90px;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
  @media (max-width: 1000px) {
    flex-direction: column;
    /* padding-left: ${p => p.theme.paddingOnSides}; */
    /* padding-right: ${p => p.theme.paddingOnSides}; */
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
    margin-bottom: 60px;
  }
`

const Image = styled(GatsbyImage)`
  width: calc(100vw / 3);
  @media (max-width: 1000px) {
    box-shadow: 0 12px 38px ${p => p.theme.lightGray};
    width: 100%;
    margin-bottom: 40px;
  }
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 190px;
  opacity: 0.4;
  z-index: -1;
  top: -30px;
  left: 40px;
  @media (max-width: 980px) {
    left: 0px;
  }
  @media (max-width: 820px) {
    left: -40px;
  }
`

class Homepage extends React.Component {
  render() {
    const { headerImage, leftImage, midImage, rightImage, watercolorImage } = this.props.data
    return (
      <React.Fragment>
        <SEO description="Das Familienweingut bewirtschaftet knapp 12 ha Weingartenfläche rund um Gols, einer der größten Weinbaugemeinden." />
        <HeaderContainer>
          <HeaderImage alt="Weingarten" image={headerImage.childImageSharp.gatsbyImageData} />
          <HeaderText>WEINGUT GÖSCHL</HeaderText>
        </HeaderContainer>
        <TextContainer>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Das Weingut</Heading>
            <Divider alt="" src={divider} />

            <Paragraph>
              Unser Familienweingut bewirtschaftet knapp 12 ha Weingartenfläche rund um Gols, einer der größten
              Weinbaugemeinden Österreichs. Mit viel Liebe und Engagement sind wir seit Jahrzehnten darum bemüht, unsere
              Kundinnen und Kunden mit bester Qualität zu verwöhnen.
            </Paragraph>
            <Paragraph>
              Wir freuen uns immer über Ihren Besuch! Ab Hof Verkäufe, Weinverkostungen und Betriebs&shy;besichtigungen
              gibt es sehr gern auf Voranmeldung.
            </Paragraph>
            <ButtonArrow link="/Weingut/" text="Mehr Informationen" />
          </Left>
          <Right>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Unsere Weine</Heading>
            <Divider alt="" src={divider} />

            <Paragraph>
              Das Sortiment reicht von fruchtbetonten, frischen Weißweinen hin zu kräftigen, facettenreichen Rotweinen,
              die zum Teil in Barriques ausgebaut werden. Für ein extra Prickeln sorgen unsere Frizzante, erhältlich in
              drei verschiedenen Sorten. Neben der Vinifikation mit viel Herz verleiht auch das pannonische Klima
              unseren Weinen ihre ganz besondere Note.
            </Paragraph>
            <Paragraph>
              Entdecken Sie unsere Weine auch online! Unser Webshop hat durchgehend für Sie geöffnet!
            </Paragraph>
            <ButtonArrow link="/Weine/" text="Zu den Weinen" />
          </Right>
        </TextContainer>
        <ImageContainer>
          <Image alt="Weine" critical={true} image={rightImage.childImageSharp.gatsbyImageData} />
          <Link to="/Weine/">
            <Image alt="Webshop" critical={true} image={midImage.childImageSharp.gatsbyImageData} />
          </Link>
          <Image alt="Familie Göschl" critical={true} image={leftImage.childImageSharp.gatsbyImageData} />
        </ImageContainer>
        <Newsletter />
      </React.Fragment>
    )
  }
}

export default Homepage

export const query = graphql`
  {
    headerImage: file(relativePath: { eq: "photos/weingut-goeschl-weingarten.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    leftImage: file(relativePath: { eq: "photos/weingut-goeschl-kontakt.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    midImage: file(relativePath: { eq: "photos/webshop-1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    rightImage: file(relativePath: { eq: "photos/weingut-goeschl-weine.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
      childImageSharp {
        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`
